import styles from "./productinfo.module.scss"

type ProductAttribute = {
    __typename: string;
    code: string;
    label: string;
    value: string;
    position: number;
  };
  
const ProductInformation = ({ productInfoData }: { productInfoData: ProductAttribute[] }) => {
  
  return (
    <div className={styles.productInfoParent}>
        <h2 className={styles.productheading}>Product Information</h2>
        <div className={styles.productinfocontainer}>
            {productInfoData?.sort((a, b) => a?.position - b?.position)?.map((product, index) => (
                <div key={index} className={styles.productinfo}>
                    <p className={styles.productlabel}>{product.label}:</p>
                    <span></span>
                    <span className={styles.productvalue}>{product.value}</span>
                </div>
            ))}
        </div>
    </div>
  )
}

export default ProductInformation
