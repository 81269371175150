import Image from "next/image";
import styles from "./offer.module.scss";
import giftbox from "~/public/images/giftbox.png";
import reward from "~/public/images/reward.png";
import { Modal } from "@material-ui/core";
import AmayaModalContent from "../OfferPincodeSection/amayaModalContent";
import { useState } from "react";

const OfferAndRewards = ({ pdpOffers }) => {
  const [modalopen, setModalOpen] = useState(false);

  const handleOpen = async (e) => {
    setModalOpen(true);
  };

  const handleClose = async () => {
    setModalOpen(false);
  };
  
  return (
    <section className={styles.offersrewards}>
      <div className={styles.offerParent}>
      {pdpOffers?.offers?.length ? <div className={styles.boxoffers}>
        <h2>Offers</h2>
        <ul>
          {pdpOffers?.offers?.map((item, index) => (
            <li key={index}>
              <Image
                className={styles.img_icon}
                src={giftbox}
                width={20}
                height={25}
                placeholder={"blur"}
                blurDataURL={giftbox.blurDataURL}
                alt="gift-img"
              />
              {item.text}
            </li>
          ))}
        </ul>
      </div> : null}

      <div className={styles.boxrewards}>
        <h2>Rewards</h2>
        <ul>
          <li>
            <Image
              className={styles.img_icon}
              src={reward}
              width={20}
              height={25}
              placeholder={"blur"}
              blurDataURL={giftbox.blurDataURL}
              alt="gift-img"
            />
            Amaaya members will earn 32 points on the purchase of this product.
          </li>
        </ul>
        <a onClick={handleOpen}>Know more</a>
      </div>
      </div>

      <Modal
        open={modalopen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={styles.modalbody}>
          <AmayaModalContent
            classes={styles}
            styles={styles}
            handleClose={handleClose}
          />
        </div>
      </Modal>
    </section>
  );
};

export default OfferAndRewards;
