
import Image from "next/image"
import styles from "./routine.module.scss"
import SingleRoutineProduct from "./SingleRoutineProduct"

const RoutineProduct = ({product,currency}) => {
  return (
    <div className={styles.routinecontainer}>
         <div className={styles.routinecontent}>
            <h2>{product.routine_heading}</h2>
            <h3>{product.routine_subheading}</h3>
         </div>
         <div>
          <SingleRoutineProduct Routineproduct={product.routineone_products} currency={currency}/>
          <SingleRoutineProduct Routineproduct={product.routinetwo_products} currency={currency}/>    
         </div>
    </div>
  )
}

export default RoutineProduct
