import React from 'react'
import Link from "next/link";
import motif1 from '~/public/images/motif1.inline.svg';

import { useRouter } from 'next/router';
import { generateBreadcrumbs } from '/lib/services/helpers/generateBreadCrumbs';

const Breadcrumbs = ({ styles, image, isMobileDevice, categoryshortdescription, prefixUrl, imgDesktop }) => {
    const router = useRouter();
    return (
        <div className={`${styles.category_product_sec1}`}>
            <div className="container">
                {" "}
                <div
                    className={
                        imgDesktop?.length > 0 && imgDesktop[0] !== "" 
                        ? styles.ayurveda_sec1_row 
                        : styles.ayurveda_sec1_row_noimg 
                    }
                >
                    {" "}
                    {!isMobileDevice ? (
                        <div className="breadcrumbs_sec">
                            <nav><ol>
                                {generateBreadcrumbs(router, prefixUrl, true)?.map((list, id) => (
                                    <li key={id}><Link href={list.href}>{list.title}</Link></li>
                                ))}
                            </ol></nav>
                        </div>
                    ) : null}
                    {categoryshortdescription ? (
                        <div
                            className={styles.category_product_colm}
                            dangerouslySetInnerHTML={{
                                __html: categoryshortdescription,
                            }}
                        />
                    ) : null}
                </div>{" "}
            </div>
        </div>
    );
}
export default Breadcrumbs