
import React, { useEffect } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";
import Head from "next/head";
import Script from 'next/script'

import JsonLdCategory from "../Schema/jsonLdCategory";
import CategoryProductMenu from "../CategoryProductMenu";
import Products from "../Products";

import { useRouter } from "next/router";
import { getWebsiteID } from "../../lib/common-functions";
import { useCookies } from "react-cookie";
import { useIsMobile } from "/lib/hooks/useIsMobile";
import { getBreadCrumbURL, getWebsiteURL } from "/lib/services/helpers/getWebsiteURL";
import { useNotifyModal } from "/lib/hooks/useNotifyModal";

import styles from "./Category.module.css";

const NotifyModal = dynamic(() => import('../NotifyModal'))

export const Category = ({ origin, plpdata, isSsrMobile }) => {
  const [cookie, setCookie] = useCookies(["store"]);
  const website_id = getWebsiteID(cookie);
  const router = useRouter();
  const isMobileType = useIsMobile(isSsrMobile)
  const { asPath } = router;

  const { cat: { data: { categories: { items }, storeConfig } }, prod } = plpdata;
  const prefixUrl = getWebsiteURL(website_id)
  const breadCrumbUrl = getBreadCrumbURL(website_id)
  let error;
  const categoryItems = items?.[0];

  const blogIds = categoryItems?.category_blogs || ''
  const newCategoryLayout = !!categoryItems?.enable_custom_multiple_carousel_layout || false;
  const categoryUrlSuffix = ".html";

  const backUrl =
    categoryItems?.breadcrumbs &&
    categoryItems?.breadcrumbs[0]?.category_url_path + categoryUrlSuffix;

  const { showNotifyModal, handleModalClose } = useNotifyModal();

  useEffect(() => {
    if (items.length > 0) {
      window.data_3070 = [];
      window.data_3070.push({
        'PageType': 'category',
        'ProductCategoryList': categoryItems?.name
      });
      (function (d) {
        var s = d.createElement('script'); s.async = true; s.id = 'DLFNPMMP1'; s.type = 'text/javascript';
        s.src = 'https://cdn.preciso.net/aud/clientjs/ptag.js?3070';
        var a = d.getElementsByTagName('script')[0]; a.parentNode.insertBefore(s, a);
      }(document));
    }
  }, [items]);

  if (error) {
    return <div>Some error occurred. Please refresh and try again.</div>;
  }

  if (items?.length == 0) {
    return null
  }

  return (
    <>
      <Head>
        <link rel="canonical" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
        {
          (asPath.includes('/international')) ? <>
            <link rel="alternate" hrefLang="en-us" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="en-ca" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="en-au" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="en-ae" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="en-sg" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            {(categoryItems?.hreflang_url && categoryItems?.hreflang_url) ? <link rel="alternate" hrefLang="en-in" href={categoryItems?.hreflang_url} /> : null}
            {(categoryItems?.hreflang_url && categoryItems?.hreflang_url) ? <link rel="alternate" hrefLang="x-default" href={categoryItems?.hreflang_url} /> : null}
          </> : <>
            <link rel="alternate" hrefLang="en-in" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="hi-in" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="x-default" href={`${process.env.NEXTAUTH_URL}${asPath}`} />
            <link rel="alternate" hrefLang="en-gb" href={`${process.env.NEXTINTERNATION_URL}/uk/en_GB`} />
            {(categoryItems?.redirect_url) ? <link rel="alternate" hrefLang="en-us" href={categoryItems?.redirect_url} /> : null}
            {(categoryItems?.hreflang_url) ? <link rel="alternate" hrefLang="en-gb" href={categoryItems?.hreflang_url} /> : null}

          </>
        }
        {categoryItems?.meta_title ? <title>{categoryItems?.meta_title}</title> : null}
        {categoryItems?.meta_title ? (
          <meta name="title" content={categoryItems?.meta_title} />
        ) : null}
        {categoryItems?.meta_description ? (
          <meta name="description" content={categoryItems?.meta_description} />
        ) : null}
        {categoryItems?.meta_keywords ? (
          <meta name="keywords" content={categoryItems?.meta_keywords} />
        ) : null}
        {categoryItems?.meta_title ? (
          <meta property="og:title" content={categoryItems?.meta_title} />
        ) : null}
        {categoryItems?.meta_description ? (
          <meta property="og:description" content={categoryItems?.meta_description} />
        ) : null}
        {categoryItems?.image ? (
          <meta property="og:image" content={categoryItems?.image} />
        ) : null}
        {categoryItems?.meta_description ? (
          <meta
            property="twitter:description"
            content={categoryItems?.meta_description}
          />
        ) : null}
        {categoryItems?.meta_title ? (
          <meta property="twitter:title" content={categoryItems?.meta_title} />
        ) : null}

      </Head >

      {categoryItems?.url_key == "shop-the-best" &&
        <>
          <Script
            async
            strategy="afterInteractive"
            id="eventTracking"
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `!function(px){function e(t,e,c){var n="",r="";try{""!=(n=function(t,e){try{var c={};e.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(t,e,n){c[e]=n});return c.hasOwnProperty(t)?c[t]:""}catch(t){return""}}(t,c))?function(t,e,c){try{var n,r;c?((n=new Date).setTime(n.getTime()+864e5),r="; expires="+n.toGMTString()):r="",document.cookie=t+"="+e+r+";Path=/"}catch(t){}}(e,n,1):n=function(t){try{var e=document.cookie.match(new RegExp("(^| )"+t+"=([^;]+)"));if(e)return e[2]}catch(t){}return null}(e),r=""!=n&&null!=n?"&"+t+"="+n:"&"+t+"="}catch(t){}return r}var c="",n="",r="";try{n=e("ad","acf",c=window.location.href),r=e("col_ci","col_ci",c)}catch(t){console.log(t)}var a="https://ade.clmbtech.com/cde/eventTracking.htm?pixelId="+px+"&_w=1&_t=2"+n+r+"&rd="+(new Date).getTime();(new Image).src=a}('14380');`,
            }} />
          <noscript>
            <img height='1' width='1' style={{ display: 'none' }} src='https://ade.clmbtech.com/cde/eventTracking.htm?pixelId=14380&_w=1&_t=2' />
          </noscript>
        </>
      }

      {/* <Script
        async
        strategy="lazyOnload"
        src="https://rtg.l10.agency/resources/content/admit_kamaayuverda.js"
        integrity="sha384-ZFea/2gOlLb++GyhwQrGFQED8VIsWj9SO9VL1hr6acAUecKwzs8WI3M1VGWmb032"
        crossOrigin="anonymous"
      /> */}

      <Script src="https://static-cdn.trackier.com/js/trackier-websdk-init.js"/>

      <JsonLdCategory origin={origin} data={plpdata} prod={prod} />

      <header className={styles.header}>
        {backUrl && (
          <Link key={backUrl} href={backUrl} className={styles.backLink}>
            ⬅
          </Link>
        )}
      </header>

      {/* Show Products only if the Category is set for it */}
      {/PRODUCTS/.test(categoryItems?.display_mode) && (
        <React.Fragment>
          {isMobileType && (
            <CategoryProductMenu
              websiteurl={prefixUrl}
            />
          )}

          {showNotifyModal ? <NotifyModal
            handleModalClose={handleModalClose}
            className='notifyContent'
            isOpen={showNotifyModal}
            redirectUrl={categoryItems?.redirect_url}
            hreflangUrl={categoryItems?.hreflang_url}
          /> : null}

          <Products
            image={categoryItems?.image}
            image_mob={categoryItems?.image_mobile}
            img_desktop={(categoryItems?.website_banner && (categoryItems?.website_banner != null) && typeof categoryItems?.website_banner == "string") ? JSON.parse(categoryItems?.website_banner) : []}
            img_mobile={(categoryItems?.mobile_banner && categoryItems?.mobile_banner != null && typeof categoryItems?.mobile_banner == "string") ? JSON.parse(categoryItems?.mobile_banner) : []}
            categoryid={`${categoryItems?.id}`}
            categoryname={categoryItems?.name}
            categorydescription={categoryItems?.description}
            categoryshortdescription={categoryItems?.short_description}
            blogIdsArray={blogIds}
            slug={categoryItems?.url_path}
            productdata={prod}
            url_key={categoryItems?.url_key}
            cookie={cookie}
            isSsrMobile={isSsrMobile}
            prefixUrl={prefixUrl}
            breadCrumbUrl={breadCrumbUrl}
            newCategoryLayout={newCategoryLayout}
            categoryIconEnable={categoryItems?.enable_category_icons_carousel}
            categoryIcon={categoryItems?.category_icons_carousel_json_data}
            cms_data={!!categoryItems?.enable_custom_multiple_carousel_layout ? JSON.parse(categoryItems?.custom_multiple_carousel_category_layout_data || null) : null}
          />
        </React.Fragment>
      )}
    </ >
  );
};


