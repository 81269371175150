import { useState } from 'react';

export const usePincode = ({eventCommonObj, setShowLoader}) => {
    const [pincode, setPincode] = useState("");
    const [DeliveryText, setDeliveryText] = useState("Check");
    const [DeliverySuccess, setDeliverySuccess] = useState(false);
    const [DeliveryMsg, setDeliveryMsg] = useState("");
    const [expectedDelivery, setExpectedDelivery] = useState('');

    const checkDelivery = async (event) => {
        event.preventDefault();
        setDeliveryText("Checking");
        setShowLoader(true);
        try {
            const response = await fetch("/api/checkdelivery", {
                body: JSON.stringify({
                    pincode: pincode,
                }),
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
    
            setShowLoader(false);
            setDeliveryText("check");
            if (data.data.success && data.data.data.serviceable) {
                const gaModule = await import('/lib/ga');
                gaModule.CheckPincodeClick(eventCommonObj);
                setDeliverySuccess(true);
                setDeliveryMsg("Free Delivery Available");
                setExpectedDelivery(`Order before 2 PM for Delivery by ${data.data.data.tat}`);
            } else {
                setDeliverySuccess(false);
                setExpectedDelivery("");
                setDeliveryMsg("Pincode is not serviceable");
            }
        } catch (error) {
            setShowLoader(false);
            setDeliverySuccess(false);
            setExpectedDelivery("");
            setDeliveryMsg("Something went wrong, Please try again later");
        }
    };

    return {
        checkDelivery,
        setPincode,
        DeliveryText,
        DeliverySuccess,
        DeliveryMsg,
        expectedDelivery
    }
}