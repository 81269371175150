

import Image from 'next/image'
import styles from "../routine.module.scss";
import BuyNowButton from '/components/EyeSerum/BuyNowButton';


const SingleRoutineProduct = ({Routineproduct,currency}) => {    

  return (
    <div>
      {Routineproduct.map((product, index) => (
        <>
          <div className={styles.wrapper} key={index}>
            <Image src={product.routine_image.url} alt={product.name} layout="responsive" 
                  width={910} 
                  height={910}
                  objectFit="cover"  
                  loading="lazy"
            />
      
          </div>
          <BuyNowButton className={`${styles.addbag} ${styles.btnbag_hydration}`} displayIcon={false}  buttonText={"Add To Bag"} pageId={product?.sku} sku={product.sku} pageTitle='routine' price={product?.price_range?.minimum_price?.final_price?.value}/> 
         </>

      ))}
    </div>
  )
}

export default SingleRoutineProduct
