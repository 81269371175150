import { useInView } from "react-intersection-observer";
import styles from "./index.module.scss";
import dynamic from "next/dist/shared/lib/dynamic";
const SaffronComponent = dynamic(()=> import('./SaffronComponent'))

const SaffronSection = ({ product, isMobileType }) =>{

    const { ref: saffronSectionRef, inView: saffronSectionRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })
    
   return(
    <div ref={saffronSectionRef} >
            {saffronSectionRefView ? <SaffronComponent
             product={product}
             isMobileType={isMobileType}
             styles={styles}
            /> : null}
        </div>
    )
}

export default SaffronSection;