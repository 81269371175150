import { useState, useRef, useEffect } from "react";
import classes from "./index.module.scss"; // Your CSS file

const ReadMoreHTML = ({ htmlContent }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const computedStyle = window.getComputedStyle(contentRef.current);
        const lineHeight = parseFloat(computedStyle.lineHeight) || 20; // Default line height if undefined
        const maxLines = 3;
        const maxHeight = lineHeight * maxLines;

        // Measure actual lines
        const actualLines = contentRef.current.scrollHeight / lineHeight;

        // Set overflow state
        setIsOverflowing(Math.floor(actualLines) > maxLines);
      }
    };

    // Run after component mounts
    checkOverflow();

    // Run again if window resizes (optional but useful)
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  return (
    <div className={classes.pwa_desc_container}>
      <div
        ref={contentRef}
        className={`${classes.pwa_desc} ${isExpanded ? classes.expanded : classes.collapsed}`}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
      {isOverflowing && !isExpanded && (
        <button className={classes.read_more} onClick={() => setIsExpanded(true)}>
          Read More
        </button>
      )}
    </div>
  );
};

export default ReadMoreHTML;
