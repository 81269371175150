import React from 'react';
interface NetQuantityInfoSectionProps {
    classes: any;
    product: any;
    productSize: any;
    selectSize: (sku: string, price: number, index: number, minimumPrice: number) => void;
}

const NetQuantityInfoSection: React.FC<NetQuantityInfoSectionProps> = (props) => {
    const { classes, product, productSize, selectSize } = props;
    if (product?.primary_category?.toLowerCase() == 'gifting') {
        return null;
    }

    return (
        <>
            <div className={classes.pdp_sec3}>
                {product?.variants ? <div className={classes.h5pdp_quantity}>
                    <span>Available in:</span>
                    <span>(Net Quantity)</span>
                </div> : null}
                {product?.type_id === "simple" && product?.size?.label != '' ? (
                    <>
                        <div className={classes.h5pdp_quantity}>
                            <span>Available in:</span>
                            <span>(Net Quantity)</span>
                        </div>
                        <ul>
                            <li key="0">
                                <a className={classes.active}>{product?.size?.label}</a>
                            </li>
                        </ul>
                    </>
                ) : null}
                <div className={classes.productvariants}>
                    {product?.variants?.map((options: any, index: number) => (
                        <div key={index}>
                            {options.product.stock_status === "IN_STOCK" &&
                                (!options.product.salableqty.label ||
                                    options.product.salableqty.label > 0) ? (
                                <>
                                    <a
                                        className={productSize === index ? classes.active : classes.nonactive}
                                        onClick={(e) =>
                                            selectSize(
                                                options.product.sku,
                                                options.product.price.regularPrice.amount.value,
                                                index,
                                                options.product.price_range?.minimum_price.regular_price.value
                                            )
                                        }
                                    >
                                        {options.attributes?.[0]?.label || ""}
                                    </a>
                                    {product?.sku == 'ITM00141_main' && (options.attributes?.[0]?.label).toLowerCase() == '30 ml' ? <span className={styles.saving_color}>{product?.product_saving_text} </span> : ''}
                                </>

                            ) : (
                                <>
                                    <a className={classes.outOfStock}>{options.attributes?.[0]?.label || ""}</a>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default NetQuantityInfoSection;
