import dynamic from "next/dist/shared/lib/dynamic";
import { useInView } from "react-intersection-observer";
import styles from "./index.module.scss"
const ExploreBlogComponent = dynamic(() => import('./ExploreBlogComponent'))


const ExploreBlogSection = ({  urlKey, blogId, preFixUrl, eventCommonObj, isMobileType }) => {
    const { ref: exploreBlogSectionRef, inView: exploreBlogSectionRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={exploreBlogSectionRef} className={styles.pdp_sec14}>
            <div className={styles.h2pdp_sec14}>Kama Blog</div>
            {
                exploreBlogSectionRefView ? <ExploreBlogComponent urlKey={urlKey} blogId={blogId} isMobileType={isMobileType} preFixUrl={preFixUrl} eventCommonObj={eventCommonObj} /> : null
            }
        </div>
    )
}

export default ExploreBlogSection;