import styles from "./../../review.module.scss";
import rating_star from '~/public/images/new-rating-star.inline.svg';
import rating_star_blank from '~/public/images/new-star-blank.inline.svg';
import style from "../addreview.module.scss"


const StarComponent = ({ rating , showBlankStars }) => {
    const totalStars = 5;
    const starCount = rating < 1 ? 0 : Math.round(rating); // Set to 0 if rating is less than 1

    const filledStars = Array.from({ length: starCount }, (_, index) => (
        <span key={index}>
            <img
                src={rating_star}
                width={14}
                height={14}
                className={`${styles.star_imgPdp} ${style.star_imgPdp}`}
                alt="rating star"
                loading='lazy'
            />
        </span>
    ))

    const blankStars = Array.from({ length: totalStars - starCount }, (_, index) => (
        <span key={index}>
            <img
                src={rating_star_blank}
                width={14}
                height={14}
                className={`${styles.star_imgPdp} ${style.star_imgPdp}`}
                alt="rating star"
                loading='lazy'
            />
        </span>
    ))

    return (
        <div className={styles.star_rating}>
            {showBlankStars ? rating : null}
            {filledStars}
            {blankStars}
        </div>
    )
}

export default StarComponent;