import { useInView } from "react-intersection-observer";
import styles from "./index.module.scss";
import dynamic from "next/dist/shared/lib/dynamic";

const RecommendedProductsComponent = dynamic(() => import('./RecommendedProductComponent'))

const RecommendedProducts = ({ urlKey, websiteID, isMobileType, eventCommonObj }) => {
    const { ref: recommendedProductRef, inView: recommendedProductRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={recommendedProductRef} className={`row ${styles.pdp_product_scroll} ${styles.relatedProductPDP}`}>
            {recommendedProductRefView ? <RecommendedProductsComponent
                urlKey={urlKey}
                websiteID={websiteID}
                isMobileType={isMobileType}
                eventCommonObj={eventCommonObj}
                styles={styles}
            /> : null}
        </div>
    )
}

export default RecommendedProducts;