import styles from "./overview.module.scss"
import Image from "/components/CustomImage"

const OverviewSection = ({product}) => {
  return (
    <div className={styles.overviewcontainer}>
       <div className={styles.overviewcontent}>
        <h2>{product.overview_title}</h2>
        <div dangerouslySetInnerHTML={{ __html: product.overview_description}} />
       </div>
       <div className={styles.imageWrapper}>
      <img 
        src={product.overview_image} 
        alt={product?.name}
       
      />
    </div>
      
    </div>
  )
}

export default OverviewSection
