import cancel_icon from '~/public/images/cancel-icon.inline.svg';
import amaaya from '~/public/images/amaaya.inline.svg';
import reward from "~/public/images/reward.png";

const AmayaModalContent = ({ classes, styles, handleClose }) => {
    return (
        <>
            <a className={classes.close} onClick={handleClose}>
                <img src={cancel_icon} loading="lazy" width={22} height={22} />
            </a>
            <span className={styles.product_amaaya}>
                <img src={reward?.src} alt="amaaya icon" width={28} height={28} loading='lazy' />
            </span>
            <h3>1. What is Amaaya?</h3>
            <p>
                Amaaya is Kama Ayurveda’s exclusive Rewards Program. It is our special
                privileges club and as a valued Amaaya member, you can earn points every
                time you shop with us. With a host of benefits, Amaaya is designed to
                help you enjoy greater rewards.
            </p>

            <h3>2. Who is eligible to join Amaaya?</h3>
            <p>
                Any one-time purchase of 4000 Rs. or more can get you an Amaaya
                membership.
            </p>

            <h3>3. What are the benefits of Amaaya membership?</h3>
            <p>
                With an Amaaya membership, for every 100 Rs. that you spend shopping
                with us, you earn back 4 points. The more you shop, the more points you
                earn. 1 point = 1 INR. What’s more, Amaaya members also get access to a
                host of other benefits including new product previews, special offers
                and exclusive experiences with Kama.
            </p>

            <h3>4. What is the value of a point?</h3>
            <p>1 point is equivalent to 1 Rs.</p>
        </>
    )
}

export default AmayaModalContent