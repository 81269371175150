import StarComponent from "../NewReviewsComponent/AddReview/StarComponent";
import classes from "./index.module.scss"
import styles from "../../Product.module.css"
import Link from "next/link";
import NetQuantityInfoSection from "../../NetQuantityInfoSection";
import ATCBuyNowButton from "../../ATCBuyNowButton";
import right_icon2 from '~/public/images/right-icon2.inline.svg';
import snapmint_pdp from '~/public/images/snapmint-revamp-pdp.png'
import simpl_pdp from '~/public/images/simpl-revamp-pdp.png'
import simpl_blue_pdp from '~/public/images/simpl-inner-pdp.svg'
import delivery_location from '~/public/images/location-icon-pdp-revamp.png'
import Image from "next/image";
import { usePincode } from "../OfferPincodeSection/hooks/usePincode";
import ReadMoreHTML from "../ReadMoreHTML";

const NewInfoSection = ({
  enableVariantData,
  productDetails,
  product,
  isMobileType,
  openShare,
  handleShareClick,
  saleProductPrice,
  productPrice,
  currency,
  config,
  inc,
  dec,
  count,
  productsize,
  selectSize,
  buyToBag,
  childsku,
  BuyNowButtonText,
  addToBag,
  ButtonText,
  showPDPMsg,
  websiteID,
  part,
  showTermsModal,
  setShowLoader,
  eventCommonObj,
}) => {

  const cartLink = (websiteID == 1) ? "/checkout/cart" : "/international/checkout/cart"

  const isValidHex = (color) => /^#([0-9A-F]{3}){1,2}$/i.test(color);

  const {
    checkDelivery,
    setPincode,
    DeliveryText,
    DeliverySuccess,
    DeliveryMsg,
    expectedDelivery
  } = usePincode({ eventCommonObj, setShowLoader })
  return (<>
    <div className={`col-12 col-md-6 ${classes.info_col}`}
      style={{
        backgroundColor: isValidHex(product?.layout_background_color)
          ? product.layout_background_color
          : "#fbdd9e"
      }}>


      {/* Product details like name, description and reviews number */}
      <div className={classes.pdp_sec1}>
        <h4 className={classes.product_tag}>{product?.product_tag}</h4>
        <h1>{enableVariantData ? (productDetails?.productName || product.name) : product.name}</h1>
        <h6 className={classes.product_sht_desc}>{product?.product_short_description}</h6>

        <div className={classes.star_rating_share}>
          {+(product.rating_summary / 20).toFixed(1) > 2 ? (
            <>
              <div className={classes.star_rating}>
                <StarComponent rating={(product.rating_summary / 20)} showBlankStars={false} />

                <Link className={classes.star_rating_text} href="#reviewform">
                  {product.review_count} Reviews
                </Link>
              </div>

              <a className={classes.star_rating_text} onClick={() => openShare(product)}>
                <span >Share </span>
              </a>
            </>
          ) : null}
        </div>

        <p className={classes.origin}>Country of Origin: <span>India</span></p>

        {(enableVariantData && productDetails?.productDescription) ? (
          // <div
          //   className={classes.pwa_desc}
          //   dangerouslySetInnerHTML={{
          //     __html: productDetails?.productDescription || product.pwa_description,
          //   }}
          // />
          <ReadMoreHTML htmlContent={product.pwa_description} />

        ) : product?.pwa_description ? (
          // <div
          //   className={classes.pwa_desc}
          //   dangerouslySetInnerHTML={{
          //     __html: product.pwa_description,
          //   }}
          // />
          <ReadMoreHTML htmlContent={product.pwa_description} />
        ) : null}


        {/* Price and qty update section */}
        {product.stock_status == "IN_STOCK" && (!product.salableqty.label || product.salableqty.label > 0) ?
          <div className={styles.pdp_price}>
            <div className={styles.pdp_sec17}>
              <div className="row">
                <div className="col-7 col-md-9 pe-0">

                  <div className={classes.pdp_priceSec}>
                    <div className={classes.pdp_price}>
                      {(saleProductPrice > productPrice) ? (
                        <>
                          <span>MRP</span>
                          {`${currency}${productPrice.toFixed(2)}`}
                          <abbr className={styles.cutoffprice}>
                            {`${currency}${saleProductPrice.toFixed(2)}`}
                          </abbr>
                        </>
                      ) : (
                        <>
                          <span>MRP</span>
                          {`${currency}${productPrice.toFixed(2)}`}
                        </>
                      )}
                    </div>
                    <span className={classes.inclPrice}>
                      (incl. of all taxes)
                    </span>
                  </div>
                  {
                    (product.expiry_date?.label) ? <p className={styles.col_expiry}><strong>Expiry Date:</strong> {product.expiry_date.label}</p> : ''
                  }
                </div>
                {(!config.anniversary_promotion8_text || !config.anniversary_promotion8_text.split(',').includes(product.sku)) ? <div className={`col-7 ${classes.qtyParent}`}>
                  <div className={(product.variants && product?.primary_category?.toLowerCase() !== 'gifting') ? `${styles["listing_pdp"]} ${classes.plusMinusParent}` : `${styles["listing_pdp"]} ${styles["listing_pdp_simple"]} ${classes.plusMinusParent}`}>
                    <abbr>Quantity</abbr>
                    <div className={`${classes.plusMinsContainer} ${styles.number}`}>
                      <span onClick={dec} className={styles.minus}>
                        -
                      </span>
                      <small className={styles.main_number}>{count}</small>
                      <span onClick={inc} className={styles.plus}>
                        +
                      </span>
                    </div>
                  </div>
                </div> : null
                }
              </div>
            </div>{" "}
          </div> : null
        }

        <NetQuantityInfoSection classes={classes} product={product} productSize={productsize} selectSize={selectSize} />

        <ATCBuyNowButton>
          <div className={classes.pdp_sec2}>
            {(product.stock_status == "OUT_OF_STOCK" || (product.salableqty.label && product.salableqty.label <= 0)) ? (
              <ul className="col-12">
                <li className={styles.out_of_stock_btn}>
                  <button type="button" disabled>
                    Out Of Stock
                  </button>
                </li>
              </ul>
            ) : null}

            {(product.stock_status == "IN_STOCK" && (!product.salableqty.label || product.salableqty.label > 0)) ? (
              <ul>

                {(config?.anniversary_promotion8_text && config?.anniversary_promotion8_text?.split(',').includes(product.sku) && config.anniversary_promotion8_title) ?
                  ('') :
                  (<li>
                    <form onSubmit={addToBag}>
                      {product.variants ? <input
                        type="hidden"
                        name="parentsku"
                        defaultValue={product.sku}
                      /> : null
                      }
                      <input
                        type="hidden"
                        name="sku"
                        defaultValue={product.variants ? childsku : product.sku}
                      />
                      <input type="hidden" name="qty" defaultValue={count} />

                      <button type="submit">{ButtonText}</button>
                    </form>{" "}
                  </li>)}
              </ul>
            ) : null}
          </div>
        </ATCBuyNowButton>
      </div>

      <div className={classes.emi_sec}>
        {websiteID == 1 ? (
          <div className={classes.snapMint_sec}>
            <Image
              src={snapmint_pdp}
              width={60}
              height={26}
              alt="snapmint"
            />
            <div className={styles.snapmintDes}>

              <div className="snap_emi_txt"></div>
              <span
                className="snapmint_lowest_emi_value hidden"
                style={{ display: 'none' }}
                data-snapmint-price={productPrice * count}
                data-snapmint-subvention="false"
                data-snapmint-merchant_id="2850"
                data-snapmint-page="product">
              </span>
            </div>
          </div>
        ) : null}

        {/* simpl text and image */}
        {(websiteID == 1 ?
          <div className={classes.snapMint_sec}>
            <Image
              src={simpl_pdp}
              width={60}
              height={26}
              alt="simpl"
            />
            <section className={styles.simpl_text}>
              Or 3 interest free payments of
              <strong> ₹{part}</strong> with
              <a onClick={(e) => showTermsModal(e)} >
                <span>
                  <Image src={simpl_blue_pdp.src} alt='simpl' loading='lazy' width={60} height={19} />
                </span>
              </a>
            </section>
          </div> : null)}
      </div>

      {websiteID == 1 ? (
        <>

          <div className={classes.pdp_sec4}>
            <div className={classes.estd_delv_sec}>
              <div className={classes.estd_delv}>

                <Image
                  src={delivery_location}
                  alt="location"
                  width={10}
                  height={13}
                />
                <div className={classes.h5pdp_pincode}>Check Estimated Delivery</div>
              </div>
              <div className={classes.input_check}>
                <form onSubmit={checkDelivery}>
                  <input
                    type="text"
                    name="pin"
                    required
                    pattern="[0-9]{6}"
                    placeholder="123456"
                    onChange={(e) => setPincode(e.target.value)}
                  />
                  <button type="submit">{DeliveryText}</button>
                </form>
              </div>
            </div>

            <p className={classes.Pincode_text}>Guaranteed Shipping Within 24 hours</p>
            <small
              className={
                DeliverySuccess
                  ? classes.delivery_success
                  : classes.delivery_error
              }
            >
              <em>{DeliveryMsg}</em>
            </small>
            <div className={classes.h6pdp_pincode}>
              <em>{expectedDelivery}</em>
            </div>
            <div className={styles.location_icon}></div>
          </div>
        </>
      ) : null}
















    </div>
  </>)
}

export default NewInfoSection;