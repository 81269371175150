import { useInView } from "react-intersection-observer";
import styles from "./index.module.scss";
import dynamic from "next/dist/shared/lib/dynamic";

const YouthGlowSection = ({ product, isMobileType }) =>{

     const YouthGlowComponent = dynamic(()=> import('./YouthGlowComponent'))
    
        const { ref: youthGlowSectionRef, inView: youthGlowSectionRefView } = useInView({
            threshold: 0,
            triggerOnce: true,
            rootMargin: "0px 0px 200px 0px"
        })

    return (
        <div ref={youthGlowSectionRef} >
        {youthGlowSectionRefView ? <YouthGlowComponent
         product={product}
         isMobileType={isMobileType}
         styles={styles}
        /> : null}
    </div>
    )
}

export default YouthGlowSection;