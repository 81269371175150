// import Image from "next/image";
import styles from "./apply.module.scss";
import Image from "/components/CustomImage";

const HowToApply = ({ product, isMobileType }) => {
  const {
    how_to_apply_description: description,
    how_to_apply_image: image,
    how_to_apply_mobile_image: mobileImage,
    how_to_apply_title: title,
  } = product;

  console.log('first',isMobileType)

  return (
    <>
      <h2 className={styles.heading_apply}>{title}</h2>
      <div className={styles.applycontainer}>
        <div className={styles.descontainer} dangerouslySetInnerHTML={{ __html: description }} />
        <div>
          {isMobileType ? (
            <img
            src={mobileImage || ''}
            loading="lazy"
            />
            // <Image
            //   src={mobileImage || ''}
            //   alt={title || ''}
            //   width={600}
            //   height={400}
            //   layout="responsive"
            //   objectFit="cover"
            //   loading="lazy"
            // />
          ) : (
            <Image
              src={image || ""}
              alt={title || ""}
              width={860}
              height={885}
              objectFit="cover"
              loading="lazy"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HowToApply;
