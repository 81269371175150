import dynamic from "next/dist/shared/lib/dynamic";
import { memo, useRef } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./review.module.scss";
import { Star } from "@material-ui/icons";
import StarComponent from "./AddReview/StarComponent";
const ListReviews = dynamic(() => import("./ListReviews"));
const ViewAllReviews = dynamic(() => import("./ViewAllReviews"));
const AddReviews = dynamic(() => import("./AddReview"));

const ListReviewsMemoized = memo(ListReviews);
const ViewAllReviewsMemoized = memo(ViewAllReviews);
const AddReviewsMemoized = memo(AddReviews);

const NewProductReviewComponent = ({
  preFixUrl,
  classes,
  setShowLoader,
  productcategory,
  eventCommonObj,
  product,
  currency,
}) => {
  const { ref: reviewsSectionRef, inView: reviewsSectionRefView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: "0px 0px 200px 0px",
  });
  const reviewFormRef = useRef(null);

  return (
    <div
      ref={reviewsSectionRef}
      id="reviewform"
      className={styles.reviewWrapper}
    >
      {reviewsSectionRefView ? (
        <>
          {product?.reviews?.items?.length > 0 ? (
            <>
              <div className={styles.reviewwhead}>User Reviews and Ratings</div>
              <section className={styles.ratingContainer}>
                <div className={styles.rating}>
                  <div className={styles.averageRating}>
                    <div className={styles.starRating}>
                      <span>4.0</span>
                      <StarComponent rating={4} showBlankStars={0} />
                    </div>
                    <span className={styles.avgtxt}>AVERAGE RATING</span>
                  </div>
                  <div className={styles.reviews}>
                    <p>{product?.review_count}</p>
                    <span>Review</span>
                  </div>
                </div>
                <div className={styles.actionButton}>
                  <button
                    onClick={() => {
                      reviewFormRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                      });
                    }}
                  >
                    Write a review
                  </button>
                </div>
              </section>
              <ListReviewsMemoized productReviews={product?.reviews} />{" "}
            </>
          ) : (
            <>
              <div className={styles.reviewwhead}>User Reviews and Ratings</div>
              <div className={styles.actionButton}>
                  <button
                    onClick={() => {
                      reviewFormRef.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                      });
                    }}
                  >
                    Write a review
                  </button>
              </div>
            </>
          )}

          <div className={styles.review_view_main}>
            {product?.reviews?.items?.length > 0 ? (
              <ViewAllReviewsMemoized
                productReviews={product?.reviews}
                productId={product?.id}
                reviewCount={product.review_count}
                preFixUrl={preFixUrl}
              />
            ) : null}
            <div ref={reviewFormRef}>
              <AddReviewsMemoized
                classes={classes}
                setShowLoader={setShowLoader}
                productcategory={productcategory}
                eventCommonObj={eventCommonObj}
                product={product}
                currency={currency}
                preFixUrl={preFixUrl}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default NewProductReviewComponent;
